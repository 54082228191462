import { createGlobalStyle } from "styled-components";
import Ledsim from "./clock_font.ttf";

const FontStyles = createGlobalStyle`

@font-face {
  font-family: 'Ledsim';
  src: url(${Ledsim}) format('truetype');
}`;

export default FontStyles;