import {useEffect, useState} from 'react';

export const useArrayState = (array, startingIndex = 0) => {

    const [index, setIndex] = useState(startingIndex ? startingIndex : 0)
    const [value, setValue] = useState(array[index])

    useEffect(() => setValue(array[index]), [index])

    const incrementIndex = () => {
        array.hasOwnProperty(index + 1) ? setIndex(prev => prev + 1) : setIndex(0)
    };

    const decrementIndex = () => array.hasOwnProperty(index - 1) 
        ? setIndex(prev => prev - 1) 
        : setIndex(0)

    return [index, setIndex, value, incrementIndex, decrementIndex];

  }