import styled from 'styled-components'
import ConfettiPrize from './ConfettiPrize'
import { ReactComponent as BorderBottomSVG } from './../resources/prize_border_bottom.svg';
import { ReactComponent as BorderTopSVG } from './../resources/prize_border_top.svg';


const PrizeContainer = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  pointer-events: auto
`

const TextContainer = styled.div`
  padding-top: 50px;
`

const PrizeText = styled.p`
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
  width: 80%;
  font-size: 1.2em;
  font-family: 'Cormorant SC', serif;
  letter-spacing: 1px;
  line-height: 1.2;
  white-space: pre-line;
`

const GameText = styled.p`
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 0;
  margin-bottom: 100px;
  width: 80%;
  font-size: 1.2em;
  font-family: 'Press Start 2P', cursive;
  letter-spacing: 1px;
  line-height: 1.2;
  color: white;
  white-space: pre-line
`

const GameNameText = styled.p`
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 0;
  margin-bottom: 20px;
  width: 80%;
  font-size: 2.8em;
  font-family: 'Press Start 2P', cursive;
  line-height: 1.2;
  white-space: pre-line
`

const VoucherText = styled.p`
  margin-top: 10px;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
  font-size: 2em;
  font-family: 'Ballet', sans-serif;
  letter-spacing: 1px;
  line-height: 1.2;
  white-space: pre-line
`

const Prize = ({ text, voucher, color, setCanMove, showConfetti }) => {

  setCanMove(true)

  const borderStyle = {
    width: '80%',
    height: '10%',
    fill: color,
    stroke: color,
    display: "block",
    margin: "auto",
    paddingTop: "20px",
    paddingBottom: "20px",
  }

  return (
    <PrizeContainer>
      {showConfetti ? <ConfettiPrize color={color}/> : null}
      {text.toLowerCase().startsWith("game time") ?
        <>
          <TextContainer>
            <GameText>{text}</GameText>
          </TextContainer>
          <GameNameText>{voucher}</GameNameText>
        </> :
        <>
          <TextContainer>
            <PrizeText>{text}</PrizeText>
          </TextContainer>
          <BorderTopSVG style={borderStyle}></BorderTopSVG>
          <VoucherText>{voucher}</VoucherText>
          <BorderBottomSVG style={borderStyle}></BorderBottomSVG>
        </>
      }
    </PrizeContainer>
  );
}

export default Prize;