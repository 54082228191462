
import styled from 'styled-components'

const VialContainer = styled.div`
  height: 100%;
  width: 100%;
`


const VialText = styled.p`
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translateX(-50%); 
  font-size: 3em;
  letter-spacing: 25px;
  font-family: 'Playfair Display SC', serif;
`

const Vial = ({setCanMove}) => {

  setCanMove(true)

  return (
    <VialContainer>
      <VialText>drip</VialText>
    </VialContainer>
  )
};

export default Vial;