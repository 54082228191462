import { useEffect, memo, useState, useRef } from "react";

const Sound = memo(({ src }) => {

  const audioRef = useRef();
  const [sound, setSound] = useState(src)

  useEffect(() => {
    audioRef.current.play()    
  }, [])

  useEffect(() => {
    if (sound !== src) {
      audioRef.current.pause()
      setSound(src)
      audioRef.current.load()
      audioRef.current.play()    
    }
  }, [src])

  return (
    <audio ref={audioRef} src={sound} autoPlay/>
  );
})

export default Sound;