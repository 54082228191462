
import { useEffect, useState, memo } from "react"

import styled from 'styled-components';


const ScreenFlasherContainer = styled.div`
    top: 0;
    background-color: ${props => props.color};
    width: 100%;
    height: 100%;
    opacity: 50%;
    position: fixed;
    pointer-events: none;
`

const ScreenFlasher = memo(({ flash, duration, color }) => {

    const [_flash, setFlash] = useState(false)

    useEffect(() => {
        flash.current = () => setFlash(true)
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            setFlash(false)
        }, duration);
        return () => clearInterval(interval)
    }, [flash]);

    return <>{_flash ? <ScreenFlasherContainer color={color} /> : null} </>
})

export default ScreenFlasher;
