import { useEffect, useState, useRef, memo } from "react"

import styled from 'styled-components';


const AnswerTextInput = styled.input`
    pointer-events: auto;
    position: absolute;
    display: block;
    bottom: 60px;
    left: 50%; 
    transform: translate(-50%, -50%);
    width: calc(80% - 6px);
    height: 50px;
    display: block;
    padding-bottom: 15px;
    background-color:rgba(0, 0, 0, 0);
    border: none;
    color: inherit;
    border-bottom: 3px solid;
    font-size: 1.8em;
    font-family: 'Permanent Marker', cursive;
    text-align: center;
    &:focus {
        outline: none;
    }
`

const AnswerInput = memo(({ input, setInput, color, isKeyboardOpen }) => {

    const focus = useRef(null);

    const onInputChange = (e) => {
        if (e.key === 'Enter') return
        setInput(e.target.value.toUpperCase())
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            focus.current.blur()
        }
    }

    return (
            <AnswerTextInput
                ref={focus}
                onClick={(e) => e.stopPropagation()}
                color={color}
                type="text"
                value={input}
                onChange={onInputChange} 
                onKeyDown={onKeyDown}
                isKeyboardOpen={isKeyboardOpen}/>
    )
})

export default AnswerInput;
