import { useState, useEffect } from 'react';

import styled from 'styled-components'

import Text from './Text'


const Title = styled.h1`
  margin: auto;
  font-size: 2.2em;
  letter-spacing: 2px;
  padding-bottom: 25px;
  padding-top: 60px;
  text-align: center;
  font-family: 'EB Garamond', serif;
  position: fixed;
  width: 100%;
`

const Start = ({ title, text, setCanMove }) => {

  setCanMove(true)

  const [hide, setHide] = useState(true)

  useEffect(() => {
    setHide(false)
  }, [])

  return (
    <div style={{opacity: hide ? "0" : "1", transition: "width 0.5s, height 0.5s, opacity 10s 1s", height: "100%", width: "100%"}}>
      <Title>Welcome<br/>Adventurer</Title>
      <Text text={text} top="20%" height="63%"/>
    </div>
  );
}

export default Start;