import { useState } from "react";
import { BrowserView, MobileView }  from 'react-device-detect';

import styled from 'styled-components';

import Hunt from './Hunt'

import { useLocalStorage } from '../hooks/localStorageHook';

import DATA from '../resources/hunt_demo.json';
const pages = DATA.hunt

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
`

const TitleContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: white;
  z-index: 9999;
  opacity: ${props => props.hidden ? 0 : 1};
  transition: opacity 2s;
`

const TitleText = styled.div`
  position: fixed;
  top: 45%;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  letter-spacing: 2px;
`

const TitleScreen = ({hidden, onClick}) => {
  return (
    <TitleContainer hidden={hidden} onClick={() => onClick(true)}>
      <TitleText>click to enter</TitleText>
    </TitleContainer>
  )
}

const BrowserContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: white;
  z-index: 9999;
  opacity: ${props => props.hidden ? 0 : 1};
  transition: opacity 2s;
`

const BrowserText = styled.div`
  position: fixed;
  top: 45%;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  letter-spacing: 2px;
`

const BrowserScreen = () => {
  return (
    <BrowserContainer>
      <BrowserText>this application is only visible on mobile</BrowserText>
    </BrowserContainer>
  )
}


export default function App() {

  const [highestIndex, _] = useLocalStorage("clue-highest-index", 0)
  const [huntStarted, setHuntStarted] = useState(highestIndex !== 0);


  return (
    <AppContainer>
      <BrowserView>
      <BrowserScreen/>
      </BrowserView>
      <MobileView>
      <TitleScreen hidden={huntStarted} onClick={setHuntStarted}/>
      { huntStarted ? <Hunt pages={pages}/> : null }
      </MobileView>
    </AppContainer>
  );
}