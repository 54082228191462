import { useState, useEffect } from 'react';

import styled from 'styled-components'

import Text from './Text'
import ConfettiPrize from './ConfettiPrize'


const Title = styled.h1`
  margin: auto;
  font-size: 1.8em;
  letter-spacing: 2px;
  padding-bottom: 25px;
  padding-top: 80px;
  text-align: center;
  font-family: 'Cinzel Decorative', cursive;
  position: fixed;
  width: 100%;
`

const CONFETTI_COLORS = [
  '#f44336', '#e91e63', '#9c27b0', '#673ab7', 
  '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', 
  '#009688', '#4CAF50', '#8BC34A', '#CDDC39', 
  '#FFEB3B', '#FFC107', '#FF9800', '#FF5722', 
  '#795548'
]

const End = ({ title, text, setCanMove, color }) => {

  setCanMove(true)

  const [hide, setHide] = useState(true)

  useEffect(() => {
    setHide(false)
  }, [])

  return (
    <div style={{opacity: hide ? "0" : "1", transition: "width 0.5s, height 0.5s, opacity 5s 1s"}}>
      <ConfettiPrize color={color}/>
      <Title>{title}</Title>
      <Text text={text} top="22%" height="60%"/>
    </div>
  );
}

export default End;