import { useEffect, useState, memo } from 'react';

import styled from 'styled-components'


const BackgroundImage = styled.img`
  width: 100%;
  height: ${window.innerHeight};
  opacity: ${props => props.opacity !== undefined ? props.opacity : 1};
  transition: opacity ${props => props.transition}s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  pointer-event: none;
`

const Background = memo(({ src, duration }) => {

  const [topSrc, setTopSrc] = useState(src);
  const [bottomSrc, setBottomSrc] = useState(src);
  const [bottomOpacity, setBottomOpacity] = useState(1);
  const [transition, setTransition] = useState(0);

  useEffect(() => {
    if (topSrc !== src) {
      setBottomSrc(topSrc)
      setTopSrc(src)
      setBottomOpacity(1)
      setTransition(0)
      const interval = setInterval(() => {
        setTransition(duration / 1000)
        setBottomOpacity(0);
      }, 100);
      return () => clearInterval(interval)
    }
  }, [src])


  return (
    <>
      <BackgroundImage src={topSrc} key={1} alt={topSrc} />
      <BackgroundImage opacity={bottomOpacity} transition={transition} src={bottomSrc} key={2} alt={bottomSrc} />
    </>
  );
})

export default Background;