
import styled from 'styled-components'


const ChapterContainer = styled.div`
  height: 100%;
  width: 100%;
`


const ChapterText = styled.p`
  position: fixed;
  top: 40%;
  left: 50%;
  width: 80%;
  text-align: center;
  transform: translateX(-50%); 
  font-size: 2em;
  letter-spacing: 5px;
  font-family: 'Playfair Display SC', serif;
`

const Chapter = ({setCanMove, text, blocked}) => {
  setCanMove(!blocked)

  return (
    <ChapterContainer>
      <ChapterText>{text}</ChapterText>
    </ChapterContainer>
  )
};

export default Chapter;