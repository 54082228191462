import { memo } from 'react';

import Confetti from 'react-confetti'


const CONFETTI_COLORS = [
  '#f44336', '#e91e63', '#9c27b0', '#673ab7', 
  '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', 
  '#009688', '#4CAF50', '#8BC34A', '#CDDC39', 
  '#FFEB3B', '#FFC107', '#FF9800', '#FF5722', 
  '#795548'
]

const ConfettiPrize = memo(({ color }) => {


  return (
    <>
      <Confetti width={window.innerWidth}
        height={window.innerHeight}
        colors={CONFETTI_COLORS}
        confettiSource={{ x: 0, y: window.innerHeight, w: 0, h: 0 }}
        initialVelocityY={15}
        initialVelocityX={10}
        numberOfPieces={300}
        gravity={0.05}
        recycle={false} />
      <Confetti wyidth={window.innerWidth}
        height={window.innerHeight}
        colors={CONFETTI_COLORS}
        confettiSource={{ x: window.innerWidth, y: window.innerHeight, w: 0, h: 0 }}
        initialVelocityY={15}
        initialVelocityX={10}
        numberOfPieces={300}
        gravity={0.05}
        recycle={false} />
    </>
  );
})

export default ConfettiPrize;