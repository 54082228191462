import { memo, useState, useEffect } from 'react';
import useDetectKeyboardOpen from "use-detect-keyboard-open";

import styled from 'styled-components';

import Text from './Text';
import AnswerInput from './AnswerInput';


const ClueContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
`

const Clue = memo(({ text, answer, alreadyAnswered, setCanMove }) => {

  const isKeyboardOpen = useDetectKeyboardOpen();
  const [input, setInput] = useState("")

  
  useEffect(() => {
    if (!alreadyAnswered && !answerIsCorrect() ) {
      setCanMove(false)
    } else {
      setCanMove(true)
    }}, [input]
  )

  const answerIsCorrect = () => {
    return input.toString().trim().toLowerCase() == answer.toString().trim().toLowerCase()
  }

  return (
    <ClueContainer id="clue-container">
      {!isKeyboardOpen ? <Text text={text} top={"10px"} height={"65%"} /> : null }
      {!alreadyAnswered ?
        <AnswerInput
          input={input}
          setInput={setInput}
          isKeyboardOpen={isKeyboardOpen}
          answer={answer} /> : null}
    </ClueContainer>
  );
})

export default Clue;