import { useState, useCallback, useEffect, useRef } from "react";

import styled from 'styled-components';

import Page from './Page'
import Controls from './Controls'

import { useLocalStorage } from '../hooks/localStorageHook';


const HuntContainer = styled.div`
  width: 100%;
  height: 100%;
  color: ${props => props.color};
`

const Hunt = ({ pages }) => {

  const [index, setIndex] = useLocalStorage("clue", 0)
  const [highestIndex, setHighestIndex] = useLocalStorage("clue-highest-index", 0)
  const [canMove, _setCanMove] = useState(true)
  const setCanMove = useCallback(_setCanMove, [_setCanMove])
  const onCantMove = useRef(null)

  const [controlsDisabled, _setControlsDisabled] = useState(false)
  const setControlsDisabled = useCallback(_setControlsDisabled)

  const [page, setPage] = useState(pages[index])

  const nextPage = useCallback(() => {
    if (canMove) {
      pages.hasOwnProperty(index + 1) ? setIndex(prev => prev + 1) : setIndex(0)
    } else {
      onCantMove.current()
    }
  }, [index, setIndex])

  const prevPage = useCallback(() => {
    pages.hasOwnProperty(index - 1) ? setIndex(prev => prev - 1) : setIndex(0)
  }, [index, setIndex, pages])


  useEffect(() => {
    _setCanMove(false)
    if (index > highestIndex) setHighestIndex(index)
    if (index < highestIndex) _setCanMove(true)
    setPage(pages[index])
  }, [index, _setCanMove])

  return (
    <HuntContainer id="hunt-container"
      color={page.color}>
      <Controls onPrev={prevPage} onNext={nextPage} controlDisabled={controlsDisabled}>
        <Page
          page={page}
          pageSeen={highestIndex > index}
          onCantMove={onCantMove}
          setCanMove={setCanMove}
          setControlsDisabled={setControlsDisabled}
        />
      </Controls>
    </HuntContainer >
  );
}

export default Hunt;