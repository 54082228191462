import styled from "styled-components";
import { SwitchTransition, Transition } from "react-transition-group";

import Start from './Start'
import MemoizedClue from './Clue'
import Prize from './Prize'
import End from './End'
import Vial from './Vial'
import Chapter from './Chapter'


const PageContentContainer = styled.div`
  opacity: ${props => props.hidden ? 0 : 1};
  transition: opacity 2s ease-in-out;
  -moz-transition: opacity 2s ease-in-out;
  -webkit-transition: opacity 2s ease-in-out;
  display: block;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
`

const FadeDiv = styled.div`
  transition: 1s;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  display: ${({ state }) => (state === "exited" ? "none" : "block")};
  height: 100%;
  width: 100%;
`;

const Shader = styled.div`
  background-color: black;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0.3;
  height: 100%;
  width: 100%;
`;

const FadeTransition = ({ children, ...rest }) => (
  <Transition {...rest}>
    {state => <FadeDiv state={state}>{children}</FadeDiv>}
  </Transition>
);


const PageContent = ({ page, pageSeen, setCanMove, hidden }) => {

  return (
    <PageContentContainer id="page-container" hidden={hidden}>
      <SwitchTransition mode="out-in">
        <FadeTransition
          key={page.backgroundImage}
          timeout={500}
          unmountOnExit
          mountOnEnter
        >
          {page.type !== "chapter" ? <Shader /> : null }
          {
            page.type === "start" ?
              <Start
                title={page.title}
                setCanMove={setCanMove}
                text={page.text} />
              : page.type === "clue" ?
                <MemoizedClue
                  text={page.text}
                  answer={page.answer}
                  setCanMove={setCanMove}
                  alreadyAnswered={pageSeen}
                />
                : page.type === "prize" ?
                  <Prize
                    showConfetti={!pageSeen}
                    text={page.text}
                    voucher={page.voucher}
                    color={page.color}
                    setCanMove={setCanMove}
                  />
                  : page.type === "end" ?
                  <End
                    title={page.title}
                    setCanMove={setCanMove}
                    text={page.text} />
                    : page.type === "vial" ?
                    <Vial setCanMove={setCanMove}/>
                    : page.type === "chapter" ?
                    <Chapter 
                      setCanMove={setCanMove}
                      text={page.text}
                      blocked={page.blocked}/>
                    : null                    
          }
        </FadeTransition>
      </SwitchTransition>
    </PageContentContainer>
  )
};


export default PageContent;