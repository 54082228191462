import { useEffect, useRef } from 'react';

import styled from 'styled-components'


const TextContainer = styled.div`
  pointer-events: auto;
  position: absolute;
  display: block;
  width: 70%;
  height: ${props => props.height};
  top: ${props => props.top};
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 10%;
  overflow: scroll;
  white-space: pre;
  -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0,0,0,1) 15%, rgba(0,0,0,1) 87%, rgba(0,0,0,0) 93%);
`

const TextContent = styled.p`
  margin: 0;
  font-size: 1.3em;
  line-height: 1.4;
  font-family: 'EB Garamond', serif;
  letter-spacing: 1px;
  white-space: pre-line;
  text-align: left;
`

const Text = ({ text, top, height }) => {

  const textContainer = useRef(null);
  useEffect(()=> textContainer.current.scrollTo(0,0))
  return (
    <TextContainer ref={textContainer} top={top} height={height}>
      <br/>
      {text.split('\n\n').map((line, i) => 
        <div key={i}>
          <br/>
          <br/>
          <TextContent>{line}</TextContent>
          </div>
      )}
      <br/><br/>
    </TextContainer>
  );
}

export default Text;
