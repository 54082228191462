import { useState, useEffect } from "react";

import styled from 'styled-components';
import { useSwipeable } from "react-swipeable";


const ControlsContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
`

const Controls = ({ children, onNext, onPrev, controlDisabled }) => {

  const [onCoolDown, setOnCoolDown] = useState(false)

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (controlDisabled || onCoolDown) return
      setOnCoolDown(true)
      return eventData.dir === "Left" ? onNext() : eventData.dir === "Right" ? onPrev() : null
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setOnCoolDown(false);
    }, 2000);
    return () => clearInterval(interval)
  }, [onCoolDown]);

  return (
    <ControlsContainer id="controls-container"{...handlers}>
      {children}
    </ControlsContainer >
  );
}

export default Controls;