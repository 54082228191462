import { useEffect, useState, useCallback } from "react";

import styled from 'styled-components'
import moment from 'moment'


const CountdownText = styled.p`
  position: absolute;
  display: block;
  bottom: 10px;
  left: 50%; 
  transform: translate(-50%, -50%);
  width: 80%;
  height: 30px;
  display: block;
  pointer-events: none;
  margin: 0;
  text-align: center;
  font-size: 2.3em;
  font-family: "Ledsim";
  letter-spacing: 15px;
  font-display: block;
  color: ${props => props.deadlinePassed ? "#f75252" : "white"}
`

const Countdown = ({ deadline }) => {

  const todaysDateString = moment(new Date()).format("YYYY-MM-DD")
  const [timeLeft, setTimeLeft] = useState(0);

  const getTimeLeft = useCallback(() => {
    const deadlineFormatted = new Date(`${todaysDateString} ${deadline}:00`)
    const now = new Date()
    return deadlineFormatted - now - (60 * 60 * 1000)
  }, [todaysDateString, deadline])

  const padDigit = (digit) => {
    return String(digit).padStart(2, '0')
  }

  const formatTimestamp = (timestamp) => {
    const tsAsDate = new Date(Math.abs(timestamp))
    const hours = padDigit(tsAsDate.getHours())
    const minutes = padDigit(tsAsDate.getMinutes())
    const seconds = padDigit(tsAsDate.getSeconds())
    return `${timestamp < 0 ? "-" : ""}${hours}:${minutes}:${seconds}`
  }

  useEffect(() => {
    const interval = setInterval(() => setTimeLeft(getTimeLeft()));
    return () => { clearInterval(interval); }
  }, [deadline]);

  return (
    <CountdownText deadlinePassed={timeLeft < 0}>{formatTimestamp(timeLeft)}</CountdownText>
  )
}

export default Countdown;
