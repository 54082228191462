import { useEffect, memo } from 'react'

import styled from 'styled-components'

import ScreenFlasher from './ScreenFlasher'
import Map from './Map'
import PageContent from './PageContent'
import Background from './Background'
import Countdown from './Countdown'
import Sound from './Sound'

import { useArrayState } from '../hooks/useArrayState'

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
`

const Page = memo(({ page, pageSeen, onCantMove, setCanMove, setControlsDisabled }) => {

  const [index, setIndex, value, incrementIndex] = useArrayState(["BACKGROUND", "PAGE_CONTENT", "MAP"], 2)

  const switchView = () => incrementIndex()

  useEffect(() => {
    if (index === 2) {
      setControlsDisabled(true)
    } else {
      setControlsDisabled(false)
    }
  }, [index])

  useEffect(() => {
    setIndex(1)
  }, [page])

  return (
    <PageContainer onClick={switchView}>
      <Background src={page.backgroundImage} duration={2000} />
      <ScreenFlasher flash={onCantMove} color={"red"} duration={2000} />
      <PageContent
        hidden={value !== "PAGE_CONTENT"}
        page={page}
        setCanMove={setCanMove}
        pageSeen={pageSeen} />
       <Map
        longitude={page.longitude}
        latitude={page.latitude}
        radius={page.radius}
        color={page.color}
        hidden={value !== "MAP"} />
      { page.deadline != null ? <Countdown deadline={page.deadline}/> : null }
      <Sound src={page.mp3}/> 
    </PageContainer>
  )
});

export default Page;