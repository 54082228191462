import { useRef, useEffect, useState } from 'react'

import styled from 'styled-components'
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import * as turf from "@turf/turf";

mapboxgl.accessToken = 'pk.eyJ1IjoidW5ydWZmbGVkLW5pZ2h0aW5nYWxlIiwiYSI6ImNrc2FidDlmajNxa3kyeG1zaGxxYTFrc2QifQ.FWSwFCCsy-EBH58ojSzzdg';


const MapContainer = styled.div`
  opacity: ${props => props.hidden ? 0 : 1};
  pointer-events: ${props => props.hidden ? "none" : "auto"};
  transition: opacity 2s ease-in-out;
  -moz-transition: opacity 2s ease-in-out;
  -webkit-transition: opacity 2s ease-in-out;
  display: block;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0.4) 85%, rgba(0,0,0,0.15) 87.5%,  rgba(0,0,0,0) 90%);
`

const Map = ({ longitude, latitude, radius, hidden, color }) => {

  const mapContainer = useRef(null);
  const map = useRef(null);
  const zoom = 13;

  const [mapLoading, setMapLoading] = useState(true)
  const [userLongitude, setUserLongitude] = useState(null)
  const [userLatitude, setUserLatitude] = useState(null)

  useEffect(() => {
    initMap()
    getUserLocation()
  }, []);

  useEffect(() => {
    addLayer()
  }, [longitude, latitude])

  useEffect(() => {
    setColor()
  }, [color])

  useEffect(() => {
    addUserLocation()
    const interval = setInterval(() => {
      getUserLocation()
    }, 30000);
    return () => clearInterval(interval)    
  }, [userLongitude, userLatitude])

  const initMap = () => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/unruffled-nightingale/cl7m3jvl5000n15qwenl3b4ak',
      center: [longitude , latitude - 0.015],
      zoom: zoom,
      maxZoom: zoom,
      minZoom: zoom,
      attributionControl: false.valueOf,
    });
  
    map.current.addControl(
      new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      // When active the map will receive updates to the device's location as it changes.
      trackUserLocation: true,
      // Draw an arrow next to the location dot to indicate which direction the device is heading.
      // showUserHeading: true
      })
      );
    // map.current.scrollZoom.disable();
  }

  const getUserLocation = () => {
    console.log("Fetching location data")
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        console.log("Location data data retrieved")
        setUserLatitude(Number(position.coords.latitude))
        setUserLongitude(Number(position.coords.longitude))
      });
    } else { 
      console.warn('Location data not received. User marker will not be displayed') 
    }
  }

  const setColor = () => {
    if (!map.current.isStyleLoaded()) {
      setTimeout(function () {
        setColor();
      }, 100);
      return
    }

    map.current.setPaintProperty("road-minor-low", "line-color", color);
    map.current.setPaintProperty("road-minor-case", "line-color", color);
    map.current.setPaintProperty("road-street-low", "line-color", color);
    map.current.setPaintProperty("road-street-case", "line-color", color);
    map.current.setPaintProperty("road-secondary-tertiary-case", "line-color", color);
    map.current.setPaintProperty("road-primary-case", "line-color", color);
    map.current.setPaintProperty("road-major-link-case", "line-color", color);
    map.current.setPaintProperty("road-motorway-trunk-case", "line-color", color);
    map.current.setPaintProperty("road-major-link", "line-color", color);
    map.current.setPaintProperty("road-minor", "line-color", color);
    map.current.setPaintProperty("road-street", "line-color", color);
    map.current.setPaintProperty("road-secondary-tertiary", "line-color", color);
    map.current.setPaintProperty("road-primary", "line-color", color);
    map.current.setPaintProperty("road-motorway-trunk", "line-color", color);
  }

  const addLayer = () => {
    if (!map.current.isStyleLoaded()) {
      setTimeout(function () {
        addLayer();
      }, 100);
      return
    }
    
    if (map.current.getLayer('circleData') !== undefined) map.current.removeLayer("circleData");
    if (map.current.getSource('circleData') !== undefined) map.current.removeSource("circleData");

    let center = turf.point([longitude, latitude]);
    let options = {
      steps: 50,
      units: 'kilometers'
    };

    let circle = turf.circle(center, radius, options);

    map.current.addSource("circleData", {
      type: "geojson",
      data: circle,
    });

    map.current.addLayer({
      id: "circleData",
      type: "fill",
      source: "circleData",
      paint: {
        "fill-color": "white",
        "fill-opacity": 1,
      },
    });

    setMapLoading(false)
  }

  const addUserLocation = () => {
    if (userLatitude == null || userLongitude == null) return;
    if (!map.current.isStyleLoaded()) {
      setTimeout(function () {
        addLayer();
      }, 200);
      return

    }
    if (map.current.getLayer('userData') !== undefined) map.current.removeLayer("userData");
    if (map.current.getSource('userData') !== undefined) map.current.removeSource("userData");
    
    let center = turf.point([userLongitude, userLatitude]);
    let options = {
      steps: 50,
      units: 'kilometers'
    };

    let circle = turf.circle(center, 0.03, options);

    map.current.addSource("userData", {
      type: "geojson",
      data: circle,
    });

    map.current.addLayer({
      id: "userData",
      type: "fill",
      source: "userData",
      paint: {
        "fill-color": "black",
        "fill-opacity": 1,
      },
    });
  }

  return (
    <MapContainer id="map-container" hidden={hidden | mapLoading}>
      <div ref={mapContainer} 
      style={{ 
        height: '100%', width: '100%'
      }} />
    </MapContainer>
  );
}

export default Map;